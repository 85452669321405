import React from 'react';
import Icon, { IIcon } from './Icon';

const IconPhone: React.FC<IIcon> = ({ color, ...props }) => (
  <Icon viewBox="0 0 64 64" {...props}>
    <path
      d="M22.8335 36.4624C19.6548 35.8659 16.4761 35.2784 13.2974 34.6818C12.5708 34.5463 11.8261 34.8807 11.6172 35.6309C11.4355 36.2906 11.8442 37.1583 12.5708 37.3029C15.7495 37.8995 18.9282 38.4869 22.107 39.0835C22.8335 39.219 23.5783 38.8846 23.7871 38.1345C23.9688 37.4656 23.5601 36.598 22.8335 36.4624Z"
      fill={color}
    />
    <path
      d="M8.23729 44.5156C12.0608 45.4194 15.948 46.034 19.8623 46.3232C23.3226 46.5763 27.1552 46.9288 29.9162 44.4252C32.2866 42.2741 33.213 38.9028 34.2574 35.9925C35.6288 32.1693 36.9548 28.3371 38.2353 24.4868C39.7066 20.0761 41.1234 15.6474 42.4948 11.2096C43.0125 9.53753 40.3787 8.82351 39.8701 10.4865C37.6722 17.6087 35.3472 24.6947 32.886 31.7264C31.8143 34.7723 30.9697 38.1526 29.2713 40.9274C28.5629 42.0843 27.6002 43.0694 26.2743 43.4762C24.7121 43.9552 22.9775 43.7925 21.3699 43.7112C17.1831 43.4942 13.0417 42.8706 8.96385 41.9035C7.25643 41.4968 6.52986 44.1088 8.23729 44.5156Z"
      fill={color}
    />
    <path
      d="M39.7798 10.5135C40.3974 9.02222 40.9332 7.31399 40.4064 5.71422C39.8797 4.11445 38.4447 3.20159 36.8554 2.85814C34.9935 2.46046 33.0318 2.38814 31.1427 2.1893C29.0539 1.97238 26.965 1.6922 24.8761 1.53855C23.1869 1.41201 21.3886 1.55663 19.9809 2.58699C18.6458 3.55408 17.9556 5.03635 17.3653 6.51863C15.8304 10.4141 14.3954 14.3458 12.9151 18.2684C11.3802 22.3085 9.85441 26.3486 8.31954 30.3887C7.60206 32.2867 6.68477 34.2118 6.15801 36.1641C5.70391 37.8542 6.22158 39.4811 7.80186 40.3488C9.37306 41.2074 11.3893 41.2888 13.1149 41.587C15.2673 41.9576 17.4107 42.3372 19.5631 42.7078C22.978 43.2953 26.4292 43.3314 28.3546 39.9692C29.3899 38.1615 29.9621 36.0466 30.6796 34.1034C31.5333 31.7986 32.3779 29.4848 33.2316 27.1801C34.9027 22.6338 36.5829 18.0876 38.254 13.5323C38.8625 11.8874 36.2287 11.1824 35.6293 12.8092C33.1317 19.597 30.6251 26.3847 28.1275 33.1724C27.5735 34.6728 27.0831 36.2183 26.4473 37.6916C25.8388 39.1106 25.0033 40.2132 23.3594 40.3669C21.9699 40.5025 20.5349 40.1409 19.1817 39.9059C17.2563 39.5715 15.3309 39.2371 13.3964 38.8936C12.0886 38.6677 10.5265 38.6225 9.30948 38.0712C8.53751 37.7187 8.68282 37.2216 8.90987 36.4985C9.21866 35.5224 9.63644 34.5734 9.99972 33.6153C11.2712 30.2712 12.5336 26.927 13.8051 23.5828C15.0766 20.2387 16.339 16.8945 17.6105 13.5504C18.1917 12.0139 18.773 10.4864 19.3542 8.94992C19.8538 7.63033 20.2806 5.87691 21.3977 4.91886C22.5784 3.90657 24.3585 4.18676 25.7843 4.33137C27.8732 4.53925 29.9621 4.77424 32.051 5.0002C33.604 5.16289 35.6293 5.03635 37.0552 5.75038C38.6264 6.52767 37.6455 8.61551 37.1551 9.79952C36.483 11.4083 39.1168 12.1043 39.7798 10.5135Z"
      fill={color}
    />
    <path
      d="M22.7871 4.88281C22.4239 5.68721 22.1696 6.53681 22.4965 7.40448C22.769 8.14562 23.3411 8.72407 24.0405 9.0856C25.3755 9.75443 26.9921 9.97134 28.4543 10.1792C30.0255 10.3961 31.6149 10.5317 33.1952 10.6311C33.9853 10.6763 34.8118 10.5679 35.4203 10.0256C35.9561 9.54655 36.2013 8.88675 36.3194 8.19985C36.4466 7.47678 36.1196 6.73565 35.3658 6.52777C34.7119 6.347 33.8218 6.75372 33.6856 7.47678C33.6493 7.67562 33.622 7.89255 33.5494 8.08235C33.5585 8.06427 33.622 7.94678 33.6584 7.94678C33.7492 7.91966 33.7038 7.91966 33.5312 7.93774C33.4222 7.93774 33.3132 7.9287 33.2043 7.91966C32.9863 7.91062 32.7774 7.89254 32.5594 7.87447C31.4514 7.79312 30.3434 7.71178 29.2445 7.5762C28.2364 7.44967 27.2283 7.25987 26.2383 6.99776C25.8569 6.89833 25.4209 6.81699 25.1031 6.57296C25.1485 6.60911 25.0395 6.37412 25.0577 6.54584C25.0486 6.50065 25.094 6.347 25.1303 6.25662C25.43 5.58779 25.321 4.80147 24.6399 4.40378C24.0677 4.05129 23.0868 4.21398 22.7871 4.88281Z"
      fill={color}
    />
  </Icon>
);

export default IconPhone;
