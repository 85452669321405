import React from 'react';
import TryBlock from './TryBlock';
import styled from 'styled-components';
import colors from 'theme/colors';
import AboutBlock from './Aboutblock';
import Animation from './Animation';
import WhatWeHaveBlock from './WhatWeHaveBlock';
import ScreensBlock from './ScreensBlock';
import Logo from 'Components/Logo';

const Container = styled.div`
  background-color: ${colors.paleGreen};
  color: ${colors.green};
`;

const Problem: React.FC = () => (
  <Container>
    <Logo />
    <Animation>
      <AboutBlock />
      <WhatWeHaveBlock />
      <ScreensBlock />
    </Animation>
    <TryBlock />
  </Container>
);

export default Problem;
