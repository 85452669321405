import Flex from 'Components/Flex';
import Box from 'Components/Box';
import React from 'react';
import styled from 'styled-components';
import { getVwLg, getVwSm, mediaLgDown, mediaLgUp } from 'theme/helpers/css';
import tryBgM from './img/try_bg-m.svg';
import tryBgPc from './img/try_bg-pc.svg';
import woman from './img/woman.svg';
import { Title, Text } from 'Components/Typography';
import { EnumFontFamily, EnumTextType } from 'theme';
import Timer from './Timer';
import AdContainer from 'Components/AdContainer';
import Button from 'Components/Button';
import { URLs } from '../../constants';
import Disclaimer, { EnumDisclaimers } from 'Components/Disclaimer';

const Container = styled(Flex).attrs({
  column: true,
  end: true,
  bottom: true,
  px: { sm: 15, lg: 160 },
  pb: { sm: 95, lg: 144 },
  pt: { sm: 1990, lg: 426 },
})`
  position: relative;
  background-size: 100% auto;
  background: top / 100% url('${tryBgM}') no-repeat;

  ${mediaLgUp} {
    justify-content: flex-start;
    height: ${getVwLg('3480px')};
    background: center / ${getVwLg('1600px')} auto url('${tryBgPc}') no-repeat;
  }
`;

const Woman = styled.img.attrs({ src: woman })`
  width: ${getVwLg('776px')};
  height: auto;
  position: sticky;
  bottom: ${getVwLg('60px')};
`;

const ScrollContainer = styled(Flex).attrs({ column: true, end: true })`
  height: ${getVwLg('1960px')};
  align-self: flex-start;

  ${mediaLgDown} {
    display: none;
  }
`;

const StyledTitle = styled(Title).attrs({
  fontFamily: EnumFontFamily.introscript,
  textType: { sm: EnumTextType.h4, lg: EnumTextType.h2 },
})`
  margin-bottom: ${getVwSm('4px')};

  ${mediaLgUp} {
    margin-bottom: ${getVwLg('18px')};
  }
`;

const TextContainer = styled(Flex).attrs({ column: true, middle: true })`
  ${mediaLgUp} {
    text-align: right;
    width: ${getVwLg('500px')};
  }
`;

const TimerContainer = styled.div`
  transform: rotate(15deg);
  position: absolute;
  width: ${getVwSm('176px')};
  top: ${getVwSm('682px')};
  right: ${getVwSm('126px')};

  ${mediaLgUp} {
    transform: rotate(13deg);
    width: ${getVwLg('160px')};
    top: ${getVwLg('804px')};
    right: ${getVwLg('354px')};
  }
`;

const DisclaimerWrapper = styled(Flex).attrs({
  bottom: true,
})`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
`;

const TryBlock: React.FC = () => (
  <Container>
    <ScrollContainer>
      <Woman />
    </ScrollContainer>

    <TimerContainer>
      <Timer />
    </TimerContainer>

    <TextContainer mb={{ sm: 34, lg: 70 }} mt={{ lg: 96 }}>
      <StyledTitle
        lvl={2}
        fontFamily={EnumFontFamily.introscript}
        textType={{ sm: EnumTextType.h4, lg: EnumTextType.h2 }}
      >
        К чему это приводит?
      </StyledTitle>
      <Text>
        Перенапряжение зрительной системы становится основной причиной развития Компьютерного Зрительного Синдрома. Об
        этом синдроме, его симптоматике и&nbsp;последствиях вы&nbsp;узнаете в&nbsp;статье.
      </Text>

      <Button to={URLs.symptoms} mt={{ sm: 24, lg: 32 }}>
        Читать
      </Button>
    </TextContainer>

    <AdContainer>
      <Title textType={EnumTextType.h3}>Что делать?</Title>

      <Box mt={{ sm: 8, lg: 12 }} mb={{ sm: 16, lg: 20 }}>
        <Text>
          Вы можете снизить нагрузку на глаза, не меняя ваш привычный ритм жизни. Врачи-офтальмологи подскажут вам
          простые, но эффективные методы.
        </Text>
      </Box>

      <Button href={URLs.makeAppointment}>Записаться на приём</Button>
    </AdContainer>

    <DisclaimerWrapper>
      <Disclaimer type={EnumDisclaimers.NOT_DOCTOR} />
    </DisclaimerWrapper>

    <Box px={{ lg: 20 }} mt={16}>
      <TextContainer>
        <Text textType={EnumTextType.note} align="left">
          * В подсчёте приведена сумма данных двух исследований среднесуточного потребления контента россиянами:
          <ul>
            <li>
              1. ТВ: Mediascope 2019. <u>Публикация РБК с данными исследования</u>
            </li>
            <li>
              2. Интернет: <u>We Are Social 2020</u>
            </li>
          </ul>
        </Text>
      </TextContainer>
    </Box>
  </Container>
);

export default TryBlock;
