import { Text } from 'Components/Typography';
import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import styled from 'styled-components';
import { EnumFontFamily, EnumFontWeight, EnumTextType } from 'theme';
import { getVwLg, getVwSm, mediaLgUp } from 'theme/helpers/css';

const StyledText = styled(Text).attrs({
  fontFamily: EnumFontFamily.introscript,
  textType: EnumTextType.h3,
  align: 'start',
})`
  font-weight: ${EnumFontWeight.md};
  font-size: ${getVwSm('58px')};

  ${mediaLgUp} {
    font-size: ${getVwLg('72px')};
  }
`;

const time = Date.now() + 3.6e7 - 1;

const Timer: React.FC = () => (
  <Countdown
    date={time}
    renderer={({ hours, minutes, seconds }) => (
      <StyledText>
        {hours}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </StyledText>
    )}
  />
);

export default Timer;
