import React from 'react';
import Icon, { IIcon } from './Icon';

const IconMask: React.FC<IIcon> = ({ color, ...props }) => (
  <Icon viewBox="0 0 72 64" {...props}>
    <path
      d="M17.5651 12.9067C16.1132 11.355 14.6613 9.80332 13.2188 8.25163C12.8582 7.86611 12.5071 7.49024 12.1465 7.10472C11.9852 6.93124 11.8333 6.73848 11.6625 6.57464C11.5107 6.43007 11.8143 6.81559 11.6151 6.50718C11.4822 6.29515 11.634 6.55538 11.5866 6.44936C11.5676 6.37226 11.5676 6.38188 11.5961 6.49754C11.6245 6.59392 11.6151 6.68066 11.5866 6.77704C11.634 6.55537 11.5486 6.8445 11.4917 6.9216C11.3019 7.18182 10.9223 7.30711 10.6091 7.22037C10.5617 7.2011 10.5142 7.17219 10.4668 7.15292C10.5807 7.22038 10.5902 7.23002 10.4953 7.1722C10.4193 7.07582 10.3529 7.00834 10.296 6.89269C10.3055 6.93124 10.315 6.97944 10.3339 7.01799C10.3244 6.86378 10.315 6.8445 10.3244 6.95052C10.315 7.19146 10.315 7.43241 10.2865 7.67335C10.2106 8.4251 10.9982 9.14794 11.71 9.11903C12.5451 9.08047 13.048 8.48293 13.1334 7.67335C13.2093 6.91196 13.1714 6.12167 12.7443 5.4663C12.2319 4.66636 11.2355 4.18447 10.3055 4.44469C9.39445 4.69527 8.81558 5.56267 8.76813 6.49754C8.73017 7.32639 9.12874 8.02996 9.65067 8.6275C10.5712 9.70694 11.5866 10.7093 12.5545 11.7405C13.5605 12.8103 14.5569 13.8801 15.5628 14.9595C16.0752 15.5089 17.0622 15.5282 17.5746 14.9595C18.0966 14.362 18.106 13.4946 17.5651 12.9067Z"
      fill={color}
    />
    <path
      d="M20.5261 14.7379C21.5131 13.3982 22.5 12.049 23.4775 10.7093C22.7278 10.8057 21.9876 10.9021 21.2379 10.9984C22.3197 12.2321 23.9804 13.1573 25.4988 12.0586C25.8499 11.808 26.1156 11.4707 26.2769 11.0659C26.4288 10.6804 26.3718 10.2852 26.4762 9.89971C26.4003 10.1888 26.3149 9.97682 26.5806 9.9961C26.9792 10.025 27.3872 10.0346 27.7858 10.0539C29.418 10.1407 30.7846 9.35999 31.9044 8.21309C33.1285 6.96018 34.2199 5.54343 35.3586 4.20377C35.8426 3.63514 35.9565 2.71954 35.3586 2.16054C33.8498 0.763059 31.3445 1.69794 31.4299 3.88572C31.4963 5.73618 34.3432 5.74582 34.2768 3.88572C34.2863 4.16522 33.5556 4.38689 33.3468 4.20377C33.3468 3.51949 33.3468 2.84483 33.3468 2.16054C32.3504 3.32672 31.3729 4.51218 30.3575 5.65908C29.6363 6.47829 28.9341 7.2204 27.7858 7.16258C26.4857 7.09511 25.1856 7.07583 24.2461 8.15527C23.9994 8.43477 23.8191 8.76246 23.7242 9.1287C23.6862 9.34073 23.6578 9.55275 23.6388 9.76478C23.5819 10.0925 23.6293 10.1021 23.7716 9.79371C24.0848 9.68769 23.9709 9.70696 23.8381 9.57203C23.6388 9.36964 23.4395 9.16724 23.2497 8.95521C22.5854 8.19382 21.5226 8.55042 21.0101 9.24435C20.0232 10.584 19.0363 11.9333 18.0588 13.273C17.6033 13.8994 17.9544 14.8921 18.5713 15.2487C19.302 15.692 20.0611 15.3644 20.5261 14.7379Z"
      fill={color}
    />
    <path
      d="M42.6949 31.556C42.7423 28.0093 42.6379 24.3855 41.224 21.0893C39.9428 18.1209 37.6463 15.7885 34.5716 14.8248C31.3166 13.8031 27.6441 14.1501 24.3227 14.5742C20.6407 15.0368 17.0251 15.8656 13.5328 17.1282C10.8662 18.092 8.0098 19.4124 6.83307 22.2266C6.14981 23.8747 6.19725 25.648 6.42501 27.3828C6.67174 29.2719 6.97541 31.1512 7.2696 33.0306C7.77255 36.2014 8.73102 39.565 11.5685 41.3962C12.9919 42.3118 14.7096 42.5335 16.3608 42.6202C18.2113 42.7166 20.0713 42.707 21.9313 42.601C26.0783 42.3697 30.2158 41.6854 34.211 40.5481C37.4091 39.6421 42.3627 38.3989 42.2299 34.1197C42.1729 32.2596 39.326 32.2596 39.3829 34.1197C39.4209 35.4497 38.2442 36.1147 37.1718 36.5484C35.7009 37.1556 34.1256 37.5893 32.5883 38.0037C29.6749 38.7844 26.7141 39.3241 23.7153 39.5843C20.802 39.8445 17.4616 40.1722 14.5862 39.5361C11.7677 38.9097 10.7523 36.1918 10.2494 33.5992C9.96469 32.1536 9.77489 30.6982 9.55663 29.2429C9.32887 27.7202 8.94928 26.101 9.13908 24.5493C9.45224 21.9375 11.9101 20.8002 14.0832 20.0099C19.5114 18.0245 25.6797 16.6849 31.4685 17.1764C34.5432 17.4366 36.9536 18.9401 38.3865 21.7351C39.9144 24.7421 39.9049 28.2792 39.8574 31.5753C39.829 33.4161 42.6759 33.4161 42.6949 31.556Z"
      fill={color}
    />
    <path
      d="M46.3959 34.5821C45.874 29.2524 44.9819 23.9516 43.6534 18.7664C43.1125 16.6654 42.6949 14.1884 41.3568 12.4344C39.8859 10.4972 37.6464 10.3333 35.4163 10.4972C30.0735 10.8827 24.7308 11.4224 19.4165 12.1067C16.8258 12.4344 14.2351 12.8006 11.6539 13.1958C9.32892 13.5524 6.72873 13.6776 4.5271 14.5547C2.48681 15.3643 1.38599 17.0413 1.50936 19.2772C1.65171 21.9083 2.11671 24.5587 2.42987 27.1802C2.76201 29.9656 3.08466 32.7413 3.39782 35.5266C3.64456 37.724 3.82486 39.9889 4.60302 42.0707C5.53302 44.5572 7.24118 46.3113 9.93627 46.4848C10.9422 46.5523 11.9386 46.3788 12.9255 46.2535C14.833 46.0125 16.7499 45.9354 18.6574 45.733C24.076 45.174 29.4567 44.2777 34.8184 43.285C37.3712 42.8128 40.0093 42.4658 42.5241 41.8008C44.925 41.1647 46.9938 39.2661 46.3959 36.5482C45.9973 34.7363 43.2548 35.4977 43.6534 37.3192C44.0519 39.1215 40.0093 39.3721 38.9654 39.5648C36.9251 39.9504 34.8848 40.3455 32.8445 40.7117C28.7829 41.4538 24.7118 42.1381 20.6122 42.62C17.585 42.9766 14.5483 43.2176 11.5211 43.5356C9.84137 43.7091 8.6077 43.6705 7.71566 42.0899C6.96597 40.7696 6.69077 39.1504 6.48199 37.6565C6.15934 35.2856 5.93159 32.8955 5.65638 30.5149C5.39067 28.2115 5.11547 25.908 4.84027 23.6046C4.69792 22.4095 4.54608 21.2241 4.42272 20.029C4.33731 19.2098 4.13802 18.2556 4.80231 17.6484C5.32424 17.1762 6.18781 17.0316 6.85209 16.8967C8.00035 16.6654 9.1676 16.5015 10.3253 16.3184C12.5744 15.9618 14.833 15.6245 17.0915 15.3161C21.7131 14.6896 26.3441 14.1692 30.994 13.7644C32.2277 13.6584 33.4709 13.5524 34.714 13.456C35.7959 13.3789 37.0865 13.1283 38.1303 13.4945C40.0093 14.1499 40.3604 17.4557 40.7875 19.1134C42.116 24.1925 43.0365 29.3777 43.5395 34.611C43.7293 36.4229 46.5762 36.4422 46.3959 34.5821Z"
      fill={color}
    />
  </Icon>
);

export default IconMask;
