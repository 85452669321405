import React, { useRef } from 'react';
import Flex from 'Components/Flex';
import styled from 'styled-components';
import useAnimation from 'hooks/useAnimation';
import useScrollListener from 'hooks/useScrollListener';
import { throttle } from 'lodash';
import useIsMobile from 'hooks/useIsMobile';

import parkAnimationM from './json/park-m.json';
import parkAnimationPc from './json/park-pc.json';
import { mediaLgUp } from 'theme/helpers/css';

const AnimationContainer = styled(Flex).attrs({
  column: true,
  mb: { sm: 431, lg: 0 },
})`
  order: 0;
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 0;

  ${mediaLgUp} {
    justify-content: center;
  }

  svg {
    width: 100% !important;
    height: auto !important;
    flex-shrink: 0;
  }
`;

const ChildrenContainer = styled.div`
  margin-top: -100vh;
  height: 100%;
  z-index: 1;
`;

const AnimationSection: React.FC = ({ children }) => {
  const section = useRef<HTMLDivElement>();
  const animation = useRef<HTMLDivElement>();
  const isMobile = useIsMobile();
  const animationInstance = useAnimation(isMobile ? parkAnimationM : parkAnimationPc, animation);

  const animate = () => {
    if (animationInstance.isLoaded && section.current && animation.current) {
      const { offsetHeight: sectionHeight } = section.current;
      const { offsetHeight: animationHeight, offsetTop: animationOffset } = animation.current;
      const { totalFrames } = animationInstance;
      const newFrame = totalFrames * (animationOffset / (sectionHeight - animationHeight));

      animationInstance.goToAndStop(newFrame > 0 ? (newFrame < totalFrames ? newFrame : totalFrames) : 0, true);
    }
  };

  useScrollListener(throttle(animate, 50));

  return (
    <Flex ref={section} column>
      <AnimationContainer ref={animation} />
      <ChildrenContainer>{children}</ChildrenContainer>
    </Flex>
  );
};

export default AnimationSection;
