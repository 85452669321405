import { Text } from 'Components/Typography';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { StyledTitle, TextBlock, TextBlockWrapper } from './styled';
import aboutBgM from './img/about_bg-m.svg';
import aboutBgPc from './img/park_bg-pc.svg';
import { getVwSm, mediaLgDown, mediaLgUp } from 'theme/helpers/css';
import Flex from 'Components/Flex';
import Box from 'Components/Box';
import useScrollListener from 'hooks/useScrollListener';
import { throttle } from 'lodash';
import useIsMobile from 'hooks/useIsMobile';

const Container = styled(Flex).attrs({
  end: true,
  bottom: true,
})`
  height: 2800px;

  ${mediaLgUp} {
    height: 2800px;
  }
`;

const StyledTextBlock = styled(TextBlock)`
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
  background-color: unset;

  ${mediaLgDown} {
    padding-top: ${getVwSm('143px')};
    background-image: url('${aboutBgM}');
  }

  ${mediaLgUp} {
    background-image: url('${aboutBgPc}');
  }
`;

const AboutBlock: React.FC = () => {
  const isMobile = useIsMobile();
  const wrapper = useRef<HTMLDivElement>();
  const animated = useRef<HTMLDivElement>();

  const setTransoformStyle = (perc: number) => {
    animated.current.style.transform = `translate${isMobile ? 'Y' : 'X'}(${perc}%)`;
  };

  const handleScroll = () => {
    if (wrapper.current) {
      const { offsetHeight, offsetTop, parentElement } = wrapper.current;
      const { innerHeight } = window;
      const animationLength = innerHeight + offsetHeight;
      const played = offsetTop + offsetHeight - (parentElement.offsetHeight - animationLength);

      if (played <= 0) setTransoformStyle(0);
      else if (played >= animationLength) setTransoformStyle(100);
      else setTransoformStyle(Math.round((played / animationLength) * 100));
    }
  };

  useScrollListener(throttle(handleScroll, 50));

  return (
    <Container>
      <TextBlockWrapper ref={wrapper}>
        <StyledTextBlock ref={animated}>
          <Box pl={{ lg: 67 }}>
            <StyledTitle align="center">В чём проблема?</StyledTitle>
          </Box>

          <Text>
            Природой предусмотрено, что глаза постоянно переводят фокус зрения, отслеживая, что происходит вокруг.
          </Text>
          <Text>
            Длительная концентрация зрения на&nbsp;одном объекте вблизи приводит к&nbsp;повышенному напряжению глаз.
          </Text>
        </StyledTextBlock>
      </TextBlockWrapper>
    </Container>
  );
};

export default AboutBlock;
