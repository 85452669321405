import React from 'react';
import { TextBlock, StyledTitle, BoldTitle, TextBlockWrapper } from './styled';
import { Text } from 'Components/Typography';
import styled from 'styled-components';
import Flex from 'Components/Flex';
import Box from 'Components/Box';
import { getVwLg, getVwSm, mediaLgDown, mediaLgUp } from 'theme/helpers/css';
import IconLaptop from 'Components/Icons/IconLaptop';
import IconPhone from 'Components/Icons/IconPhone';
import IconTv from 'Components/Icons/IconTv';
import colors from 'theme/colors';
import { NBSP } from '../../constants';

interface IList {
  items: { text: string; icon: React.ReactNode }[];
}

const Container = styled(Flex).attrs({
  end: true,
  bottom: true,
})`
  height: 2800px;
`;

const ListContainer = styled.ul`
  margin-top: ${getVwSm('15px')};
`;

const ListItem = styled(Flex).attrs({
  as: 'li',
  middle: true,
  reverse: true,
  end: true,
})`
  &:not(:first-child) {
    margin-top: ${getVwSm('8px')};
  }

  text-align: left;

  ${mediaLgDown} {
    ${Text} {
      margin-left: ${getVwSm('8px')};
    }
  }

  ${mediaLgUp} {
    &:not(:first-child) {
      margin-top: ${getVwLg('12px')};
    }

    ${Text} {
      margin-right: ${getVwLg('8px')};
    }

    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
  }
`;

const List: React.FC<IList> = ({ items }) => (
  <ListContainer>
    {items.map(({ text, icon }) => (
      <ListItem key={text}>
        <Text>{text}</Text>
        {icon}
      </ListItem>
    ))}
  </ListContainer>
);

const listIconProps = {
  w: { sm: getVwSm('32px'), lg: getVwLg('45px') },
  color: colors.green,
};

const listItems = [
  {
    text: 'монитор компьютера на работе',
    icon: <IconLaptop {...listIconProps} />,
  },
  {
    text: `смартфон или планшет в дороге, очередях${NBSP}и перерывах`,
    icon: <IconPhone {...listIconProps} />,
  },
  {
    text: 'дома нас ждет телевизор',
    icon: <IconTv {...listIconProps} />,
  },
];

const WhatWeHaveBlock: React.FC = () => (
  <Container>
    <TextBlockWrapper>
      <TextBlock>
        <Box pl={{ lg: 68 }}>
          <StyledTitle align="center">
            Что есть&nbsp;
            <BoldTitle>Сейчас?</BoldTitle>
          </StyledTitle>
        </Box>
        <Text>
          Развитие технологий <br />
          привело к тому, что весь день <br />
          у нас перед глазами экраны:
        </Text>
        <List items={listItems} />
      </TextBlock>
    </TextBlockWrapper>
  </Container>
);

export default WhatWeHaveBlock;
