import { Text } from 'Components/Typography';
import React from 'react';
import styled from 'styled-components';
import { StyledTitle, TextBlock, BoldTitle } from './styled';
import { getVwLg, getVwSm, mediaLgUp, mediaLgDown } from 'theme/helpers/css';
import stickerM from './img/sticker-m.svg';
import stcikerPc from './img/sticker-pc.svg';
import Flex from 'Components/Flex';

const Container = styled(Flex).attrs({
  end: true,
  bottom: true,
})`
  ${mediaLgUp} {
    height: 100vh;
  }
`;

const Sticker = styled.div`
  flex-shrink: 0;
  width: ${getVwSm('361px')};
  height: ${getVwSm('281px')};
  background: center / contain no-repeat url('${stickerM}');

  ${mediaLgDown} {
    margin-top: ${getVwSm('16px')};
    transform: translateX(${getVwSm('-7px')});
  }

  ${mediaLgUp} {
    margin-top: ${getVwLg('28px')};
    width: ${getVwLg('436px')};
    height: ${getVwLg('443px')};
    background: center / contain no-repeat url('${stcikerPc}');
  }
`;

const StyledTextBlock = styled(TextBlock)`
  position: relative;
`;

const ScreensBlock: React.FC = () => (
  <Container>
    <StyledTextBlock>
      <StyledTitle>
        Жизнь перед <BoldTitle>экраном</BoldTitle>
      </StyledTitle>
      <Text>
        Цифровой формат информации <br />
        усиливает нагрузку на зрительный аппарат.
      </Text>
      <Sticker />
    </StyledTextBlock>
  </Container>
);

export default ScreensBlock;
