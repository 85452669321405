import styled from 'styled-components';
import { Text, Title } from 'Components/Typography';
import { EnumFontFamily, EnumTextType } from 'theme';
import { getVwLg, getVwSm, mediaLgDown, mediaLgUp } from 'theme/helpers/css';
import Flex from 'Components/Flex';
import colors from 'theme/colors';

export const BoldTitle = styled(Text).attrs({
  fontFamily: EnumFontFamily.introscript,
  textType: EnumTextType.h1,
})`
  ${mediaLgDown} {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    display inline-block;
    text-transform: lowercase;
  }
`;

export const TextBlock = styled(Flex).attrs({
  column: true,
  top: true,
  center: true,
  py: { sm: 15 },
  px: { sm: 15 },
  pl: { lg: 262 },
  pr: { lg: 160 },
})`
  height: max-content;
  width: 100%;

  ${mediaLgDown} {
    background-color: ${colors.paleGreen60};
  }

  ${mediaLgUp} {
    align-items: flex-end;
    width: ${getVwLg('892px')};
    height: 100vh;
    text-align: end;
  }
`;

export const StyledTitle = styled(Title).attrs({
  fontFamily: EnumFontFamily.introscript,
  textType: { sm: EnumTextType.h4, lg: EnumTextType.h2 },
})`
  margin-bottom: ${getVwSm('4px')};

  ${mediaLgUp} {
    margin-bottom: ${getVwLg('18px')};
  }
`;

export const AnimatedBlock = styled(Flex).attrs({ bottom: true })`
  width: 100vw;
  height: 100vh;
`;

export const TextBlockWrapper = styled.div`
  position: sticky;
  bottom: 0;
  overflow: hidden;
`;
