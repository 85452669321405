import React from 'react';
import Layout from 'Containers/Layout';
import Footer, { FooterThemes } from 'Components/Footer';
import Navbar, { NavbarPages } from 'Components/Navbar';
import { EnumPages } from '../constants';
import Problem from 'Containers/Problem';
import colors from 'theme/colors';

const problemTheme = {
  disclaimer: {
    bgColor: colors.green,
    color: colors.paleGreen,
  },
  body: {
    color: colors.green,
  },
  ad: {
    bgColor: colors.semiPaleGreen,
  },
  button: {
    bgColor: colors.green,
    color: colors.paleGreen,
  },
};

const IndexPage: React.FC = () => (
  <Layout theme={problemTheme}>
    <Navbar page={NavbarPages.PROBLEM} />
    <Problem />
    <Footer theme={FooterThemes[EnumPages.PROBLEM]} />
  </Layout>
);

export default IndexPage;
